import React, { useCallback } from "react";
import type { IPurchaseOrder, IPurchaseOrderLineItem } from "../../../api_utils/types";
import { Button, Icon, IndexTable, TextField } from "@shopify/polaris";
import HorseVariant from "../../common/HorseVariant/HorseVariant";
import { assertNumber, assertString, formatMoney, getCurrencySymbol } from "../../../helper_functions/utils";
import { humanDateFormatWithColor } from "../../../helper_functions/component_utils";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import NiceSalesRate from "../../sales_trends/support/NiceSalesRate";
import NiceSalesRateRecommendation from "./nice_sales_rate_recommendation";
import NiceSeasonalRecommendation from "./nice_seasonal_recommendation";

const oosColor = (date: Date | string): string => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const msToDay = 1000 * 60 * 60 * 24;
  if (new Date(date).getTime() < Date.now()) {
    return "#FF0000";
  } else if (Math.ceil((new Date(date).getTime() - Date.now()) / msToDay) < 30) {
    return "#B98900";
  } else {
    return "transparent";
  }
};

export default function PurchaseOrderDraftRow({
  selectedForecastingMethod,
  currency,
  poli,
  setPoli,
  removeVariant,
  position,
  purchaseOrder,
}: {
  readonly selectedForecastingMethod: string;
  readonly currency: string;
  readonly poli: IPurchaseOrderLineItem;
  readonly setPoli: (poli: IPurchaseOrderLineItem) => void;
  readonly removeVariant: (id: number) => void;
  readonly position: number;
  readonly purchaseOrder: IPurchaseOrder;
}): React.ReactElement {
  const handleCostChange = useCallback(
    (newCost: string): void => {
      const cleanCost = newCost?.replace(/[^\d.-]/g, "");
      setPoli({ ...poli, cost: cleanCost as any as number });
    },
    [poli, setPoli],
  );

  const handleQuantityChange = useCallback(
    (newQuantity: string): void => {
      const cleanQuantity = newQuantity?.replace(/[^\d.-]/g, "");
      setPoli({ ...poli, quantity: cleanQuantity as any as number });
    },
    [poli, setPoli],
  );

  const lineTotal = assertNumber(poli.cost) * assertNumber(poli.quantity);

  const removeSelf = useCallback(() => {
    removeVariant(poli.id);
  }, [removeVariant, poli.id]);

  return (
    <IndexTable.Row id={poli.id.toString()} key={poli.id.toString()} position={position}>
      <IndexTable.Cell>
        <HorseVariant key={poli.id} variant={poli} />
      </IndexTable.Cell>
      <IndexTable.Cell>{poli.tracked ? poli.currently_available || "0" : "∞"}</IndexTable.Cell>
      <IndexTable.Cell>{poli.ordered_quantity}</IndexTable.Cell>
      {selectedForecastingMethod === "sales_rate" && (
        <IndexTable.Cell>
          <NiceSalesRate
            available_days={poli.currently_available}
            sales_quantity={poli.sales_quantity}
            sales_rate={poli.sales_rate}
          />
        </IndexTable.Cell>
      )}
      <IndexTable.Cell>
        {selectedForecastingMethod === "sales_rate" ? (
          <NiceSalesRateRecommendation
            available_quantity={poli.currently_available}
            ordered_quantity={poli.ordered_quantity}
            purchaseOrder={purchaseOrder}
            recommended_quantity={poli.needed}
            sales_rate={poli.sales_rate}
          />
        ) : (
          <NiceSeasonalRecommendation
            available_quantity={poli.currently_available}
            last_year_sales={poli.sales_quantity}
            ordered_quantity={poli.ordered_quantity}
            purchaseOrder={purchaseOrder}
            recommended_quantity={poli.needed}
          />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {poli.oos_date ? humanDateFormatWithColor(new Date(poli.oos_date), oosColor(poli.oos_date)) : ""}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div className="w120">
          <TextField
            align="right"
            autoComplete="off"
            id={poli.id.toString()}
            key={poli.id}
            label="Cost"
            labelHidden
            name="cost"
            onChange={handleCostChange}
            placeholder="0.00"
            prefix={getCurrencySymbol(currency)}
            type="currency"
            value={assertString(poli.cost)}
          />
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div className="w120">
          <TextField
            autoComplete="off"
            id={poli.id.toString()}
            key={poli.id}
            label="Quantity"
            labelHidden
            name="quantity"
            onChange={handleQuantityChange}
            placeholder="0"
            type="integer"
            value={assertString(poli.quantity)}
          />
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div className="w120">{formatMoney(lineTotal, currency)}</div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Button icon={<Icon source={MobileCancelMajor} />} onClick={removeSelf} variant="plain" />
      </IndexTable.Cell>
    </IndexTable.Row>
  );
}

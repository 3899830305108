import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  FormLayout,
  LegacyStack,
  TextField,
  Text,
  Checkbox,
  Label,
  InlineGrid,
  Select,
  BlockStack,
} from "@shopify/polaris";
import { useHorseInventoryLevels, updateHorseInventoryLevels } from "../../api_utils/requests";
import { assertNumber, assertString, extractMessageFromError, softAssertNumber } from "../../helper_functions/utils";

const defaultReason = "correction";
const defaultComment = "";

export function QuantityChangeModal({
  activatorText,
  horseVariantId,
  disabled,
  tracked,
  setErrorMessage,
}: {
  readonly activatorText: number | string;
  readonly horseVariantId: number;
  readonly disabled: boolean;
  readonly tracked: boolean;
  readonly setErrorMessage: (message: string) => void;
}): React.JSX.Element {
  const [active, setActive] = useState(false);
  // The text of the button that opens the modal
  const [dynamicActivatorTxt, setDynamicActivatorTxt] = useState(activatorText);
  const [trackedQuantity, setTrackedQuantity] = useState(tracked);
  const [selectedReason, setSelectedReason] = useState(defaultReason);
  const [stockUpdateComment, setStockUpdateComment] = useState(defaultComment);

  const options = [
    { label: "Correction (default)", value: "correction" },
    { label: "Count", value: "count_inventory" },
    { label: "Received", value: "received" },
    { label: "Return restock", value: "return_restock" },
    { label: "Damaged", value: "damaged" },
    { label: "Theft or loss", value: "theft_or_loss" },
    { label: "Promotion or donation", value: "promotion_or_donation" },
    { label: "Other", value: "other" },
  ];

  const { data: hils, refetch } = useHorseInventoryLevels(
    {
      horse_variant_id: horseVariantId,
    },
    {
      enabled: active,
    },
  );

  const [changedQuantities, setChangedQuantities] = useState<Record<string, string>>({});

  const makeActive = async (): Promise<void> => {
    await refetch();
    setActive(true);
  };

  const makeInactive = useCallback((): void => {
    setActive(false);
  }, []);

  // Make sure that dynamicActivatorTxt is updated when parent table re-renders
  useEffect(() => {
    setDynamicActivatorTxt(activatorText);
  }, [activatorText]);

  const handleChange = (newAvailable: string, updateOrCreateId: string): void => {
    const cleanAvailable = newAvailable?.replace(/[^\d.-]/g, "");
    setChangedQuantities((prev) => ({ ...prev, [updateOrCreateId]: cleanAvailable }));
  };

  const handleSave = (): void => {
    const horse_inventory_levels = hils.map((hil) => ({
      id: hil.id,
      // If it already exists, allow it to be changed to 0
      // but if it doesn't exist, then empty still means untracked
      available: hil.id
        ? assertNumber(changedQuantities[hil.updateOrCreateId] || hil.available)
        : softAssertNumber(changedQuantities[hil.updateOrCreateId] || hil.available),
      horse_location_id: hil.horse_location_id,
      horse_variant_id: hil.horse_variant_id,
    }));

    const payload = {
      horse_variant_id: horseVariantId,
      horse_inventory_levels,
      tracked: trackedQuantity,
      reason: selectedReason,
      comment: stockUpdateComment,
    };

    updateHorseInventoryLevels(payload)
      .then((res) => {
        shopify.toast.show("Quantity updated");
        if (trackedQuantity) {
          setDynamicActivatorTxt(res.reduce((accum, hil) => accum + hil.available, 0));
        } else {
          setDynamicActivatorTxt("∞");
        }
        setChangedQuantities({});
        setStockUpdateComment(defaultComment);
        setSelectedReason(defaultReason);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const errorMessage = extractMessageFromError(err);
        setErrorMessage(errorMessage);
      });
    makeInactive();
  };

  const quantityUpdated = hils.some((hil) => {
    return changedQuantities[hil.updateOrCreateId] !== undefined;
  });

  const activator = (
    <Button disabled={disabled} onClick={makeActive} variant="plain">
      {String(dynamicActivatorTxt)}
    </Button>
  );

  const rows = hils.map((hil) => {
    const available = trackedQuantity
      ? changedQuantities[hil.updateOrCreateId] !== undefined
        ? changedQuantities[hil.updateOrCreateId]
        : hil.available
          ? assertString(hil.available)
          : ""
      : "";

    return (
      <InlineGrid columns={2} key={`row-${hil.horse_location_id}-${hil.horse_variant_id}`}>
        <Label id={`${hil.horse_location_id}-label`} key={`${hil.horse_location_id}-label`}>
          {hil.horse_location.name}
        </Label>
        <TextField
          autoComplete="off"
          disabled={!trackedQuantity}
          inputMode="numeric"
          key={`field-${hil.horse_location_id}-${hil.horse_variant_id}`}
          label={hil.horse_location.name}
          labelHidden
          onChange={(newAvailable: string): void => {
            handleChange(newAvailable, hil.updateOrCreateId);
          }}
          selectTextOnFocus
          type="integer"
          value={available}
        />
      </InlineGrid>
    );
  });

  return (
    <Modal
      activator={activator}
      onClose={makeInactive}
      open={active}
      primaryAction={{
        content: "Save",
        onAction: handleSave,
        disabled: !quantityUpdated,
      }}
      title="Change quantity"
    >
      <Modal.Section>
        <FormLayout key={`horse-variants_hil-${horseVariantId}-quantity-change-form`}>
          {hils.length > 0 && (
            <FormLayout.Group>
              <LegacyStack alignment="baseline" distribution="fillEvenly">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox checked={trackedQuantity} label="Track quantity" onChange={setTrackedQuantity} />
                </div>
              </LegacyStack>
            </FormLayout.Group>
          )}
          {hils.length === 0 ? (
            <Text as="p" variant="bodySm">
              No inventory levels found
            </Text>
          ) : (
            <BlockStack gap="200">
              {rows}
              <Select label="Reason" onChange={setSelectedReason} options={options} value={selectedReason} />
              <InlineGrid>
                <Label id="stock-comment">Comment</Label>
                <TextField
                  autoComplete="off"
                  inputMode="text"
                  label="test"
                  labelHidden
                  multiline={2}
                  onChange={(value: string): void => {
                    setStockUpdateComment(value);
                  }}
                  selectTextOnFocus
                  type="text"
                  value={stockUpdateComment}
                />
              </InlineGrid>
            </BlockStack>
          )}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

import React, { useState, useCallback, useEffect, useMemo } from "react";
import qs from "query-string";
import type { SortButtonChoice, AppliedFilterInterface } from "@shopify/polaris";
import { Select, useSetIndexFiltersMode, IndexFilters } from "@shopify/polaris";
import { isEmpty, urlParamToDate } from "../../../helper_functions/utils";
import CustomRangePicker from "../../common/CustomDatePicker/CustomRangePicker";
import MoreLessFilter from "../../common/Filters/MoreLessFilter";
import type { DateRangeFilter, DecimalRangeFilter, QueryParams } from "../../../api_utils/types";
import { allLocations, useHorseLocationsOptions } from "../../../api_utils/requests";
import { dateLabel, moreThanLessThanLabel } from "../../common/Filters/label_tools";

const keys = {
  origin_locations: "transfer_orders_origin-locations",
  destination: "transfer_orders_destination",
  transferQuantity: "transfer_orders_quantity",
  sentDate: "transfer_orders_sent-date",
  receivedDate: "transfer_orders_received-date",
};
const tabs = [
  {
    id: "transfer-orders-all",
    content: "All",
    accessibilityLabel: "All transfer orders result",
    panelID: "all-transfer-orders-tab-content",
    backendState: "",
  },
  {
    id: "transfer-orders-draft",
    content: "Draft",
    accessibilityLabel: "Draft transfer orders result",
    panelID: "draft-transfer-orders-tab-content",
    backendState: "draft",
  },
  {
    id: "transfer-orders-sent",
    content: "Sent",
    accessibilityLabel: "Sent transfer orders result",
    panelID: "sent-transfer-orders-tab-content",
    backendState: "sent",
  },
  {
    id: "transfer-orders-received",
    content: "Closed",
    accessibilityLabel: "Closed transfer orders result",
    panelID: "received-transfer-orders-tab-content",
    backendState: "received",
  },
];

const sortOptions = [
  { label: "Created at", directionLabel: "Oldest/Newest", value: "created_at asc" },
  { label: "Created at", directionLabel: "Newest/Oldest", value: "created_at desc" },
  { label: "Value", directionLabel: "Small/Big", value: "value asc" },
  { label: "Value", directionLabel: "Big/Small", value: "value desc" },
  { label: "Quantity", directionLabel: "Small/Big", value: "quantity asc" },
  { label: "Quantity", directionLabel: "Big/Small", value: "quantity desc" },
  { label: "Status", directionLabel: "A-Z", value: "state asc" },
  { label: "Status", directionLabel: "Z-A", value: "state desc" },
  { label: "Sent date", directionLabel: "Latest/Earliest", value: "sent_date asc" },
  { label: "Sent date", directionLabel: "Earliest/Latest", value: "sent_date desc" },
  { label: "Received date", directionLabel: "Latest/Earliest", value: "arrived_date asc" },
  { label: "Received date", directionLabel: "Earliest/Latest", value: "arrived_date desc" },
] as SortButtonChoice[];

type DisambiguateLabelArgs =
  | {
      key: "destination";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "moreThanLessThan";
      value: DecimalRangeFilter;
      prefix?: never;
      label: string;
      suffix?: never;
    }
  | {
      key: "origin_locations";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "receivedDate";
      value: DateRangeFilter;
      prefix?: never;
      label: string;
      suffix?: never;
    }
  | {
      key: "sentDate";
      value: DateRangeFilter;
      prefix?: never;
      label: string;
      suffix?: never;
    }
  | {
      key: "transferQuantity";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    };

const disambiguateLabel = ({ key, value, label, prefix, suffix }: DisambiguateLabelArgs): string => {
  switch (key) {
    case "sentDate":
      return dateLabel(value, label);
    case "receivedDate":
      return dateLabel(value, label);
    case "origin_locations":
      return `Origin location is ${value}`;
    case "destination":
      return `Destination is ${value}`;
    case "moreThanLessThan":
      return moreThanLessThanLabel(value, label, prefix, suffix);
    default:
      throw new Error(`Unknown key: ${key}`);
  }
};

export function TransferOrdersSearchFieldAndMoreFilters({
  setFilters,
  onSelect,
  selected,
}: {
  readonly setFilters: (filters: QueryParams) => void;
  readonly onSelect: (selected: number) => void;
  readonly selected: number;
}): React.JSX.Element {
  const parsedUrlSearch = qs.parse(window.location.search);
  const curDateMonth = useMemo(() => new Date(), []);
  curDateMonth.setUTCHours(0, 0, 0, 0);

  const init = {
    // initially selected values (coming from URL)
    search: parsedUrlSearch.search as string,
    originLocation: parsedUrlSearch.origin_location as string,
    destination: parsedUrlSearch.destination_location as string,
    transferQuantity: {
      more_than: parsedUrlSearch["by_transfer_quantity[more_than]"] as string,
      less_than: parsedUrlSearch["by_transfer_quantity[less_than]"] as string,
    },
    sentDate: {
      start: urlParamToDate(parsedUrlSearch["by_sent_date[start]"]),
      end: urlParamToDate(parsedUrlSearch["by_sent_date[end]"]),
    },
    receivedDate: {
      start: urlParamToDate(parsedUrlSearch["by_received_date[start]"]),
      end: urlParamToDate(parsedUrlSearch["by_received_date[end]"]),
    },
  };
  const [originLocation, setOriginLocation] = useState<string>(init.originLocation);
  const [destination, setDestination] = useState<string>(init.destination);
  const [transferQuantity, setTransferQuantity] = useState<DecimalRangeFilter>(init.transferQuantity);
  const [queryValue, setQueryValue] = useState<string>(init.search);
  const [selectedSentRange, setSelectedSentRange] = useState<DateRangeFilter>({
    start: typeof init.sentDate.start === "number" ? new Date(init.sentDate.start) : undefined,
    end: typeof init.sentDate.end === "number" ? new Date(init.sentDate.end) : undefined,
  });
  const [selectedReceivedRange, setSelectedReceivedRange] = useState<DateRangeFilter>({
    start: typeof init.receivedDate.start === "number" ? new Date(init.receivedDate.start) : undefined,
    end: typeof init.receivedDate.end === "number" ? new Date(init.receivedDate.end) : undefined,
  });
  const sentDateIsSelected = !!selectedSentRange.start && !!selectedSentRange.end;
  const receivedDateIsSelected = selectedReceivedRange.start && selectedReceivedRange.end;
  const initSortedCol = (parsedUrlSearch["by_sort[column]"] as string) || "created_at";
  const initSortedColDir = (parsedUrlSearch["by_sort[direction]"] as string) || "desc";
  const [sort, setSort] = useState([`${initSortedCol} ${initSortedColDir}`]);

  useEffect(() => {
    // TODO: test date filter!!!!

    const [column, direction] = sort[0].split(" ");
    setFilters({
      by_received_date: selectedReceivedRange,
      by_sent_date: selectedSentRange,
      by_sort: { column, direction },
      by_transfer_quantity: transferQuantity,
      destination_location: destination,
      origin_location: originLocation,
      search: queryValue,
    });
  }, [
    setFilters,
    destination,
    originLocation,
    selectedReceivedRange,
    selectedSentRange,
    transferQuantity,
    queryValue,
    sort,
  ]);

  const { data: locationOptions } = useHorseLocationsOptions({ collection: "match_transfer_orders" });
  const originLocations = locationOptions;
  const destinations = locationOptions;

  const handleOriginLocationChange = useCallback((value: string) => {
    setOriginLocation(value === allLocations ? undefined : value);
  }, []);

  const handleDestinationChange = useCallback((value: string) => {
    setDestination(value === allLocations ? undefined : value);
  }, []);

  const handleSentMonthChange = setSelectedSentRange;
  const handleReceivedMonthChange = setSelectedReceivedRange;

  const handleSentDateRemove = useCallback(() => {
    setSelectedSentRange({
      start: undefined,
      end: undefined,
    });
  }, []);

  const handleReceivedDateRemove = useCallback(() => {
    setSelectedReceivedRange({
      start: undefined,
      end: undefined,
    });
  }, []);

  const handleOriginLocationRemove = useCallback(() => {
    setOriginLocation(undefined);
  }, []);
  const handleDestinationRemove = useCallback(() => {
    setDestination(undefined);
  }, []);
  const handleTransferCostRemove = useCallback((): void => {
    setTransferQuantity({ more_than: undefined, less_than: undefined });
  }, []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue("");
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
    handleOriginLocationRemove();
    handleDestinationRemove();
    handleSentDateRemove();
    handleReceivedDateRemove();
    handleTransferCostRemove();
  }, [
    handleQueryValueRemove,
    handleOriginLocationRemove,
    handleDestinationRemove,
    handleSentDateRemove,
    handleReceivedDateRemove,
    handleTransferCostRemove,
  ]);

  const filters = [
    {
      key: keys.origin_locations,
      label: "Origin",
      filter: (
        <Select
          id="inventory-level-histories_origin-location_select"
          label=""
          onChange={handleOriginLocationChange}
          options={originLocations}
          value={originLocation || allLocations}
        />
      ),
    },
    {
      key: keys.destination,
      label: "Destination",
      filter: (
        <Select
          id="inventory-level-histories_product-type_select"
          label=""
          onChange={handleDestinationChange}
          options={destinations}
          value={destination || allLocations}
        />
      ),
    },
    {
      key: keys.sentDate,
      label: "Sent date",
      filter: <CustomRangePicker dateRange={selectedSentRange} setDateRange={handleSentMonthChange} />,
    },
    {
      key: keys.receivedDate,
      label: "Arrival date",
      filter: <CustomRangePicker dateRange={selectedReceivedRange} setDateRange={handleReceivedMonthChange} />,
    },
    {
      key: keys.transferQuantity,
      label: "Quantity",
      filter: (
        <MoreLessFilter
          less_than={transferQuantity.less_than}
          more_than={transferQuantity.more_than}
          setStateCallback={setTransferQuantity}
          targetState={transferQuantity}
        />
      ),
    },
  ];

  const appliedFilters: AppliedFilterInterface[] = [];
  if (!isEmpty(originLocation)) {
    const key = keys.origin_locations;
    const value: string | undefined =
      originLocations.length > 0 && originLocations.find((element) => element.value === originLocation).label;
    appliedFilters.push({
      key,
      label: disambiguateLabel({ key: "origin_locations", value }),
      onRemove: handleOriginLocationRemove,
    });
  }
  if (!isEmpty(destination)) {
    const key = keys.destination;
    const value: string | undefined =
      destinations.length > 0 && destinations.find((element) => element.value === destination).label;
    appliedFilters.push({
      key,
      label: disambiguateLabel({ key: "destination", value }),
      onRemove: handleDestinationRemove,
    });
  }
  if (!isEmpty(transferQuantity.more_than) || !isEmpty(transferQuantity.less_than)) {
    const key = keys.transferQuantity;
    appliedFilters.push({
      key,
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: transferQuantity,
        label: "Quantity sent",
      }),
      onRemove: handleTransferCostRemove,
    });
  }
  if (sentDateIsSelected) {
    const key = keys.sentDate;
    appliedFilters.push({
      key,
      label: disambiguateLabel({
        key: "sentDate",
        value: selectedSentRange,
        label: "Sent date",
      }),
      onRemove: handleSentDateRemove,
    });
  }
  if (receivedDateIsSelected) {
    const key = keys.receivedDate;
    appliedFilters.push({
      key,
      label: disambiguateLabel({
        key: "sentDate",
        value: selectedReceivedRange,
        label: "Arrival date",
      }),
      onRemove: handleReceivedDateRemove,
    });
  }

  const { mode, setMode } = useSetIndexFiltersMode();

  return (
    <IndexFilters
      appliedFilters={appliedFilters}
      canCreateNewView={false}
      cancelAction={{
        onAction: () => {},
        disabled: false,
        loading: false,
      }}
      filters={filters}
      mode={mode}
      onClearAll={handleFiltersClearAll}
      onQueryChange={setQueryValue}
      onQueryClear={handleQueryValueRemove}
      onSelect={onSelect}
      onSort={setSort}
      queryPlaceholder="Search transfer orders"
      queryValue={queryValue}
      selected={selected}
      setMode={setMode}
      sortOptions={sortOptions}
      sortSelected={sort}
      tabs={tabs}
    />
  );
}

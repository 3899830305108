import LogRocket from "logrocket";

if (__env?.toLowerCase() === "production") {
  LogRocket.init("fzchrv/horse", {
    release: __version,
    shouldCaptureIP: false,
  });

  document.addEventListener("DOMContentLoaded", async () => {
    await shopify.ready;

    const user = await window.shopify.user();

    LogRocket.identify(document.querySelector("body").dataset.shop, {
      accountAccount: user.accountAccess,
    });
  });
}

import React from "react";

import { Text, Tooltip } from "@shopify/polaris";
import { formattedSalesRate } from "../../../helper_functions/utils";

export default function NiceSalesRate({
  sales_quantity,
  available_days,
  sales_rate,
  discarded = false,
  tooltip = true,
}: {
  readonly sales_quantity: number;
  readonly available_days: number;
  readonly sales_rate: number;
  readonly discarded?: boolean;
  readonly tooltip?: boolean;
}): React.JSX.Element | string {
  const calculation = `${sales_quantity} sales / ${available_days} available days`;
  const salesRate = formattedSalesRate({ sales_rate });

  // `While the variant was available for purchase, \n
  //             it sold at an average rate of \n
  //             ${salesRate}. ${calculation}`
  const content = (
    <>
      <Text as="p">While the variant was available for purchase,</Text>
      <Text as="p">it sold at an average rate of</Text>
      <Text as="p">{salesRate}</Text>
      <br />
      <Text as="strong">Calculation</Text>
      <Text as="p">{calculation}</Text>
    </>
  );

  if (tooltip) {
    return (
      <Tooltip content={content} preferredPosition="above">
        <Text as="strong" tone={discarded ? "subdued" : undefined}>
          {salesRate}
        </Text>
      </Tooltip>
    );
  } else {
    return salesRate;
  }
}

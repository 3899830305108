import React, { useCallback, useState } from "react";
import { Modal, DropZone, LegacyStack, Thumbnail, Text } from "@shopify/polaris";
import { NoteMinor } from "@shopify/polaris-icons";
import { extractMessageFromError } from "../../helper_functions/utils";

export function ImportFile<ImportType = any>({
  title,
  setErrorMessage,
  modalIsOpen,
  setModalIsOpen,
  apiCallMethod,
  setApiResponse,
}: {
  readonly title: string;
  readonly setErrorMessage: (message: string) => void;
  readonly modalIsOpen: boolean;
  readonly setModalIsOpen: (isOpen: boolean) => void;
  readonly apiCallMethod: (formData: FormData) => Promise<{ message?: string; data?: ImportType[]; errors?: string[] }>;
  readonly setApiResponse: (data: ImportType[]) => void;
}): React.JSX.Element {
  const acceptedType = "text/csv";
  const [file, setFile] = useState<File>(null);
  const [isSaving, setIsSaving] = useState(false);

  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[]): void => {
      setFile(acceptedFiles[0]);
    },
    [setFile],
  );

  const handleSave = useCallback((): void => {
    setIsSaving(true);
    const formData = new FormData();
    formData.append("file", file);

    const apiRes = apiCallMethod(formData);

    apiRes
      .then((res) => {
        shopify.toast.show(res.message, {
          isError: false,
        });
        if (setApiResponse) setApiResponse(res.data);
        setModalIsOpen(false);
        setFile(null);
        // Even if success, there could still be some warnings
        if (res.errors) {
          const newErrorMessage = extractMessageFromError(res);
          setErrorMessage(newErrorMessage);
        }
      })
      .catch((err: unknown) => {
        Rollbar.error(err, { file: file.name });
        const newErrorMessage = extractMessageFromError(err);
        setErrorMessage(newErrorMessage);
        setModalIsOpen(false);
        setFile(null);
        // TODO: Because the import can be partial, we should return what was imported successfully and set that.
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [apiCallMethod, file, setApiResponse, setErrorMessage, setModalIsOpen]);

  const fileAttachMarkup = !file && <DropZone.FileUpload />;
  const uploadedFileMarkup = file && (
    <div style={{ padding: "10%" }}>
      <LegacyStack alignment="center" distribution="center">
        <Thumbnail alt={file.name} size="small" source={NoteMinor} />
        <div>
          {file.name}{" "}
          <Text as="p" variant="bodySm">
            {file.size} bytes
          </Text>
        </div>
      </LegacyStack>
    </div>
  );

  const handleClose = useCallback(() => {
    setModalIsOpen(false);
    setFile(null);
  }, [setModalIsOpen, setFile]);

  return (
    <Modal
      onClose={handleClose}
      open={modalIsOpen}
      primaryAction={{
        loading: isSaving,
        content: "Import",
        onAction: handleSave,
        disabled: !file || isSaving,
      }}
      title={title || "Import file"}
    >
      <Modal.Section>
        <DropZone accept={acceptedType} allowMultiple={false} onDrop={handleDropZoneDrop}>
          {uploadedFileMarkup}
          <br />
          {fileAttachMarkup}
        </DropZone>
      </Modal.Section>
    </Modal>
  );
}

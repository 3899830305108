import React from "react";

import { Text, Tooltip } from "@shopify/polaris";
import type { IPurchaseOrder } from "../../../api_utils/types";
import { formattedSalesRate } from "../../../helper_functions/utils";

export default function NiceSalesRateRecommendation({
  available_quantity,
  sales_rate,
  purchaseOrder,
  ordered_quantity,
  recommended_quantity,
}: {
  readonly available_quantity: number;
  readonly sales_rate: number;
  readonly purchaseOrder: IPurchaseOrder;
  readonly ordered_quantity: number;
  readonly recommended_quantity: number;
}): React.JSX.Element | string {
  const { arrival_date, days_of_inventory } = purchaseOrder;
  const pre_ordering_days = (arrival_date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
  const depletion_date = new Date(arrival_date.getTime() + days_of_inventory * 24 * 60 * 60 * 1000);
  const ordering_days = (depletion_date.getTime() - arrival_date.getTime()) / (1000 * 60 * 60 * 24);
  const total_days = Math.round(pre_ordering_days + ordering_days);
  const needed = Math.round(sales_rate * total_days);
  const nice_sales_rate = formattedSalesRate({ sales_rate });
  const calculation = (
    <>
      <Text as="strong">Calculation</Text>
      <Text as="p">&nbsp;&nbsp;&nbsp;{nice_sales_rate} sales rate</Text>
      <Text as="p">x {total_days} days of inventory</Text>
      <Text as="p">= {needed} needed</Text>
      <br />
      <Text as="p">&nbsp;&nbsp;&nbsp;{needed} needed</Text>
      <Text as="p">- {available_quantity} currently available</Text>
      <Text as="p">- {ordered_quantity} ordered</Text>
      <Text as="p">= {recommended_quantity} recommended</Text>
      <br />
      <Text as="p">Note: Exact recommendations may vary based on other factors.</Text>
    </>
  );

  return (
    <Tooltip content={calculation} preferredPosition="above">
      <Text as="strong">{recommended_quantity}</Text>
    </Tooltip>
  );
}

import type { Bundle, ChildBundleHorseVariant, IHorseVariant, QueryParams } from "../../api_utils/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActionList,
  BlockStack,
  Box,
  Button,
  Card,
  EmptyState,
  Icon,
  IndexTable,
  InlineStack,
  Layout,
  Page,
  PageActions,
  Popover,
  SkeletonBodyText,
  SkeletonPage,
  Text,
  TextField,
} from "@shopify/polaris";
import { HorizontalDotsMinor, EditMinor, MobileCancelMajor } from "@shopify/polaris-icons";
import { isEqual, getIdFromPath, extractMessageFromError } from "../../helper_functions/utils";
import AddVariantDialog from "../common/AddVariantDialog";
import { SelectVariantDialog } from "../common/SelectVariantDialog";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import SettingToggle from "../common/SettingToggle";
import {
  deleteBundle,
  getBundle,
  updateBundle,
  updateBundleSelection,
  getBundleHorseVariants,
  removeBundleHorseVariant,
  removeAllBundleHorseVariants,
} from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import SaveBarWithConfirmation from "../common/SaveBarWithConfirmation";

const bundlesHelpPageUrl = "https://horse-inventory.notion.site/Bundles-88ac584381a445de890d4f68870d5920";
const helpUrl =
  "https://horse-inventory.notion.site/Bundles-88ac584381a445de890d4f68870d5920?pvs=4#30886ee8a00f4c35b26620d83910eee4";

type ChildBundleHorseVariants = Record<number, ChildBundleHorseVariant>;

const formatBundleHorseVariants = (bundleHorseVariants: ChildBundleHorseVariant[]): ChildBundleHorseVariants =>
  bundleHorseVariants.reduce<ChildBundleHorseVariants>((map, horseVariant) => {
    map[horseVariant.id] = horseVariant;
    return map;
  }, {});

const formatBundleHorseVariantsToSave = (bundleHorseVariants: ChildBundleHorseVariants): ChildBundleHorseVariant[] =>
  Object.values(bundleHorseVariants).map((horseVariant) => horseVariant);

const columnNames: NonEmptyArray<IndexTableHeading> = [{ title: "Variant" }, { title: "Quantity" }, { title: "" }];

const emptyState = (
  <EmptyState heading="No variants added" image="">
    <p>You have not added any variants to your bundle yet.</p>
  </EmptyState>
);

function ShowBundle(): React.JSX.Element {
  const bundleId = useMemo(() => getIdFromPath(), []);

  const [originalBundle, setOriginalBundle] = useState<Bundle>();
  const [bundle, setBundle] = useState<Bundle>();

  const [originalBundleHorseVariants, setOriginalBundleHorseVariants] = useState<ChildBundleHorseVariants>(
    {} as ChildBundleHorseVariants,
  );
  const [bundleHorseVariants, setBundleHorseVariants] = useState<ChildBundleHorseVariants>(
    {} as ChildBundleHorseVariants,
  );

  const [bundleHorseVariant, setBundleHorseVariant] = useState<IHorseVariant>();
  const [originalBundleHorseVariant, setOriginalBundleHorseVariant] = useState<IHorseVariant>();

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    shopify.loading(loading);
  }, [loading]);
  const [addVariantModalActive, setAddVariantModalActive] = useState(false);
  const [popoverActive, setPopoverActive] = useState(false);
  const [selectVariantModalActive, setSelectVariantModalActive] = useState(false);
  const [deletionModalActive, setDeletionModalActive] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const isDirty = !isEqual(bundle, originalBundle) || !isEqual(bundleHorseVariants, originalBundleHorseVariants);

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((currentPopoverActive) => !currentPopoverActive);
  }, []);

  useEffect(() => {
    getBundle(bundleId)
      .then(({ bundle: newBundle, bundleHorseVariants: newBundleHorseVariants }) => {
        setBundle(newBundle);
        setOriginalBundle(newBundle);

        setBundleHorseVariant(newBundle.horseVariant);
        setOriginalBundleHorseVariant(newBundle.horseVariant);

        const formattedHorseVariants = formatBundleHorseVariants(newBundleHorseVariants);
        setBundleHorseVariants(formattedHorseVariants);
        setOriginalBundleHorseVariants(formattedHorseVariants);

        setLoading(false);
      })
      .catch((error: unknown) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleBundleChange = useCallback((value: string | boolean, key: string) => {
    setBundle((prevBundle) => {
      return { ...prevBundle, [key]: value };
    });
  }, []);

  const handleHorseVariantUpdate = (horseVariant: IHorseVariant): void => {
    setBundleHorseVariant(horseVariant);
    setBundle({ ...bundle, horse_variant_id: horseVariant.id });
  };

  const handleVariantUpdate = useCallback(() => {
    getBundleHorseVariants(bundleId)
      .then((newBundleHorseVariants) => {
        const formattedBundleHorseVariants = formatBundleHorseVariants(newBundleHorseVariants || []);
        setBundleHorseVariants(formattedBundleHorseVariants);
        setOriginalBundleHorseVariants(formattedBundleHorseVariants);
      })
      .catch(() => {
        setBundleHorseVariants({} as ChildBundleHorseVariants);
      });
  }, [bundleId]);

  const handleHorseVariantsQuantityChange = useCallback(
    (variantId: number, newQuantity: string, key: string) => {
      const newBundleHorseVariants = JSON.parse(JSON.stringify(bundleHorseVariants)) as ChildBundleHorseVariants;
      const cleanQuantity = newQuantity?.replace(/[^\d.-]/g, "");
      newBundleHorseVariants[variantId][key] = cleanQuantity;
      setBundleHorseVariants(newBundleHorseVariants);
    },
    [bundleHorseVariants],
  );

  const handleSaveBundle = useCallback(() => {
    setLoading(true);

    const payload = {
      bundle: {
        ...bundle,
        bundle_horse_variants_attributes: formatBundleHorseVariantsToSave(bundleHorseVariants),
      },
    };
    updateBundle(bundleId, payload)
      .then((updatedBundle) => {
        shopify.toast.show("Save successful");

        setBundle(updatedBundle);
        setOriginalBundle(updatedBundle);
        setBundleHorseVariant(updatedBundle.horseVariant);
        setOriginalBundleHorseVariant(updatedBundle.horseVariant);
        setOriginalBundleHorseVariants(bundleHorseVariants);
        setErrorMessage("");
      })
      .catch((err: unknown) => {
        Rollbar.error(err, { bundleId, payload });
        const newErrorMessage = extractMessageFromError(err);
        setErrorMessage(newErrorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [bundle, bundleHorseVariants, bundleId]);

  const removeAllVariants = useCallback(() => {
    setLoading(true);
    removeAllBundleHorseVariants(bundleId)
      .then(() => {
        setBundleHorseVariants({} as ChildBundleHorseVariants);
        setOriginalBundleHorseVariants({} as ChildBundleHorseVariants);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, { bundleId });
        const newErrorMessage = extractMessageFromError(err);
        setErrorMessage(newErrorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [bundleId]);

  const removeVariant = useCallback(
    (id: number) => {
      const newBundleHorseVariants = { ...bundleHorseVariants };
      delete newBundleHorseVariants[id];
      setBundleHorseVariants(newBundleHorseVariants);
      removeBundleHorseVariant(id)
        .then(() => {
          shopify.toast.show("Variant removed");
          setOriginalBundleHorseVariants(newBundleHorseVariants);
        })
        .catch((err: unknown) => {
          Rollbar.error(err, { bundleId, variantId: id });
          const newErrorMessage = extractMessageFromError(err);
          setErrorMessage(newErrorMessage);
          setBundleHorseVariants(originalBundleHorseVariants);
        });
    },
    [bundleHorseVariants, originalBundleHorseVariants],
  );

  const handleDiscardAction = useCallback(() => {
    setBundle(originalBundle);
    setBundleHorseVariant(originalBundleHorseVariant);
    setBundleHorseVariants(originalBundleHorseVariants);
  }, [originalBundle, originalBundleHorseVariant, originalBundleHorseVariants]);

  const handleUpdateSelection = useCallback(
    async (
      payload: { bundle_horse_variants_attributes: { horse_variant_id: number }[] },
      queryParams: QueryParams,
    ): Promise<{ horse_variant_ids: number[]; errors?: string[] }> => {
      const updatePromise = updateBundleSelection(bundleId, payload, queryParams);
      void updatePromise.then(({ errors }) => {
        if (errors) {
          setErrorMessage(errors.join("\n"));
        }
      });
      return updatePromise;
    },
    [bundleId, setErrorMessage],
  );

  const activator = (
    <Button accessibilityLabel="Menu" disclosure icon={HorizontalDotsMinor} onClick={togglePopoverActive} />
  );

  const pageSkeleton = (
    <SkeletonPage>
      <Layout>
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card>
              <SkeletonBodyText lines={2} />
            </Card>
            <Card>
              <SkeletonBodyText lines={5} />
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card>
            <SkeletonBodyText lines={10} />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = (
    <Page
      backAction={{
        content: "Bundles",
        url: "/bundles",
      }}
      title={bundle?.name}
    >
      <SaveBarWithConfirmation handleDiscard={handleDiscardAction} handleSave={handleSaveBundle} isDirty={isDirty} />

      <SelectVariantDialog
        active={selectVariantModalActive}
        handleDiscard={() => {
          setBundleHorseVariant(originalBundleHorseVariant);
        }}
        selected={bundleHorseVariant}
        setActive={setSelectVariantModalActive}
        updateSelection={handleHorseVariantUpdate}
      />
      <Layout>
        {errorMessage ? (
          <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.Section variant="oneThird">
          <BlockStack gap="400">
            <Card>
              <TextField autoComplete="off" id="name" label="Name" onChange={handleBundleChange} value={bundle?.name} />
            </Card>
            <Card>
              <Text as="h6" variant="headingSm">
                Bundle parent variant
              </Text>
              <Box paddingBlock="200">
                <Text as="h6" variant="bodySm">
                  The variant that the customer buys
                </Text>
              </Box>
              <BlockStack gap="200">
                <InlineStack align="space-between" blockAlign="center">
                  {bundleHorseVariant ? <HorseVariant variant={bundleHorseVariant} /> : null}
                  <Popover
                    activator={activator}
                    active={popoverActive}
                    autofocusTarget="first-node"
                    onClose={togglePopoverActive}
                  >
                    <ActionList
                      actionRole="menuitem"
                      items={[
                        {
                          content: "Edit parent bundle variant",
                          icon: EditMinor,
                          onAction: (): void => {
                            setSelectVariantModalActive(true);
                          },
                        },
                      ]}
                    />
                  </Popover>
                </InlineStack>
              </BlockStack>
            </Card>
            <Card>
              <SettingToggle
                description="Automatically adjust the inventory of the parent according to the available quantities of its children."
                enabled={bundle?.parent_inventory_tracks_children}
                handleToggle={() => {
                  handleBundleChange(!bundle?.parent_inventory_tracks_children, "parent_inventory_tracks_children");
                }}
                helpLink={helpUrl}
                title="Track parent quantity"
              />
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section>
          <Card padding="0">
            <Box padding="400">
              <Text as="h6" variant="headingSm">
                Add child variants to the bundle
              </Text>
              <Box paddingBlock="200">
                <Text as="h6" variant="bodySm">
                  These variants are inside the bundle
                </Text>
              </Box>
            </Box>
            <BlockStack gap="200">
              <InlineStack align="center">
                <AddVariantDialog
                  active={addVariantModalActive}
                  excludableHorseVariantIds={[bundle?.horse_variant_id]}
                  handleVariantUpdate={handleVariantUpdate}
                  primary
                  removeAllVariants={removeAllVariants}
                  selected={Object.values(bundleHorseVariants).map(({ horse_variant_id }) => horse_variant_id)}
                  selectionKey="bundle_horse_variants_attributes"
                  setActive={setAddVariantModalActive}
                  setErrorMessage={setErrorMessage}
                  updateSelection={handleUpdateSelection}
                />
              </InlineStack>
              <IndexTable
                emptyState={emptyState}
                headings={columnNames}
                itemCount={Object.keys(bundleHorseVariants).length}
                selectable={false}
              >
                {Object.values(bundleHorseVariants).map((variant: ChildBundleHorseVariant, index) => (
                  <IndexTable.Row id={variant.id.toString()} key={variant.id} position={index}>
                    <IndexTable.Cell>
                      <HorseVariant key={variant.id} variant={variant} />
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div className="w120">
                        <TextField
                          autoComplete="off"
                          id="quantity"
                          label="Quantity"
                          labelHidden
                          min={1}
                          name="Quantity"
                          onChange={(value: string, key: string): void => {
                            handleHorseVariantsQuantityChange(variant.id, value, key);
                          }}
                          type="number"
                          value={String(variant.quantity ?? 0)}
                        />
                      </div>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Button
                        icon={<Icon source={MobileCancelMajor} />}
                        onClick={(): void => {
                          removeVariant(variant.id);
                        }}
                        variant="plain"
                      />
                    </IndexTable.Cell>
                  </IndexTable.Row>
                ))}
              </IndexTable>
            </BlockStack>
          </Card>
        </Layout.Section>
      </Layout>
      <PageActions
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            onAction: () => {
              setDeletionModalActive(true);
            },
          },
        ]}
      />
      <DeleteConfirmationDialog
        active={deletionModalActive}
        content="Are you sure you want to delete this bundle? This action cannot be reversed."
        deleteItem={deleteBundle}
        gotoPath="/bundles"
        invalidateQueries={{
          queryKey: ["bundles", { page: 1 }],
        }}
        itemId={bundleId}
        setActive={setDeletionModalActive}
        title="Delete bundle"
      />
      <Footer pageTitle="bundles" url={bundlesHelpPageUrl} />
    </Page>
  );

  return loading ? pageSkeleton : pageMarkup;
}

export default ShowBundle;

import React from "react";

import { Text, Tooltip } from "@shopify/polaris";
import type { IHorseVariant } from "../../../api_utils/types";
import { formattedSalesRate } from "../../../helper_functions/utils";

const TABS = {
  sales_rate: 0,
  seasonal: 1,
};

export default function NiceRemainingDays({
  variant,
  forecastingMethod,
  samplingPeriod,
}: {
  readonly variant: IHorseVariant;
  readonly forecastingMethod: number;
  readonly samplingPeriod: number;
}): React.JSX.Element | string {
  const days = variant.estimated_days_of_sales_remaining > 1 ? "days" : "day";
  const niceSalesRate = formattedSalesRate({ sales_rate: variant.sales_rate });

  const content =
    forecastingMethod === TABS.sales_rate ? (
      <>
        <Text as="p">Given the variant&apos;s</Text>
        <Text as="p">sales rate,</Text>
        <Text as="p">
          it will take {variant.estimated_days_of_sales_remaining} {days}
        </Text>
        <Text as="p">for the variant to sell out.</Text>
        <br />
        <Text as="strong">Calculation</Text>
        <Text as="p">&nbsp;&nbsp;&nbsp;{variant.currently_available} currently available</Text>
        <Text as="p">+ {variant.ordered} ordered</Text>
        <Text as="p">- {niceSalesRate}</Text>
        <Text as="p">
          = {variant.estimated_days_of_sales_remaining} {days} remaining
        </Text>
      </>
    ) : (
      <>
        <Text as="p">Given the variant&apos;s</Text>
        <Text as="p">sales last year,</Text>
        <Text as="p">
          it will take {variant.estimated_days_of_sales_remaining} {days}
        </Text>
        <Text as="p">for the variant to sell out.</Text>
        <br />
        <Text as="strong">Calculation</Text>
        <Text as="p">&nbsp;&nbsp;&nbsp;{variant.currently_available} currently available</Text>
        <Text as="p">+ {variant.ordered} ordered</Text>
        <Text as="p">- {variant.sales_quantity} sales last year</Text>
        <Text as="p">
          = {variant.estimated_days_of_sales_remaining} {days} remaining
        </Text>
      </>
    );

  return (
    <Tooltip content={content}>
      <Text as="span" tone={variant.discarded ? "subdued" : undefined}>
        {variant.estimated_days_of_sales_remaining}
      </Text>
    </Tooltip>
  );
}

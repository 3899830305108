import React from "react";

import { Text, Tooltip } from "@shopify/polaris";
import type { IPurchaseOrder } from "../../../api_utils/types";

export default function NiceSalesRateRecommendation({
  available_quantity,
  last_year_sales,
  ordered_quantity,
  recommended_quantity,
  purchaseOrder,
}: {
  readonly available_quantity: number;
  readonly last_year_sales: number;
  readonly ordered_quantity: number;
  readonly recommended_quantity: number;
  readonly purchaseOrder: IPurchaseOrder;
}): React.JSX.Element | string {
  // const { arrival_date, days_of_inventory } = purchaseOrder;
  // const pre_ordering_days = (arrival_date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
  // const depletion_date = new Date(arrival_date.getTime() + days_of_inventory * 24 * 60 * 60 * 1000);
  // const ordering_days = (depletion_date.getTime() - arrival_date.getTime()) / (1000 * 60 * 60 * 24);
  // const total_days = Math.round(pre_ordering_days + ordering_days);

  const calculation = (
    <>
      <Text as="strong">Calculation</Text>
      <Text as="p">&nbsp;&nbsp;&nbsp;{last_year_sales || 0} sales this time last year</Text>
      <Text as="p">- {available_quantity} currently available</Text>
      <Text as="p">- {ordered_quantity} ordered</Text>
      <Text as="p">= {recommended_quantity} recommended</Text>
      <br />
      <Text as="p">Note: Exact recommendations may vary based on other factors.</Text>
    </>
  );

  return (
    <Tooltip content={calculation} preferredPosition="above">
      <Text as="strong">{recommended_quantity}</Text>
    </Tooltip>
  );
}
